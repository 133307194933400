<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div>
          <div class="content-titles-and-links display-flex align-items-center">
            <p class="text-title mon-bold">{{ texts.home.warehouse.textTitle }} 📦</p>
            <v-spacer></v-spacer>
            <div class="display-flex align-items-center">
              <p
                @click="redirectWarehouses"
                class="text-link cursor-pointer text-underline mon-medium"
              >{{ texts.home.warehouse.textAllWarehouses }}</p>
              <v-icon
                color="#0071FF"
                size="18px"
              >
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
          <div
            v-if="aWarehouses.length > 0"
            class="scroll-horizontal display-flex align-items-center"
          >
            <div class="display-flex align-items-center">
              <div
                v-for="(item, index) in aWarehouses"
                :key="index"
                class="container-card mr-5"
              >
                <div>
                  <p class="text-warehouse mon-medium">{{ item.oWarehouse.sName }}</p>
                  <p
                    :style="{ height: `${restantHeight}px` }"
                    class="text-address mon-regular"
                  >
                    {{ 
                      (item.oLocation.sLocationOptionalAddress == null || item.oLocation.sLocationOptionalAddress == '')
                      ? item.oLocation.sAddress + ', ' + item.oLocation.sCity + ', ' + item.oLocation.sStateName
                      : item.oLocation.sAddress + ', ' + item.oLocation.sLocationOptionalAddress + ', ' + item.oLocation.sCity + ', ' + item.oLocation.sStateName
                    }}
                  </p>
                  <p class="text-phone mon-regular">{{ item.oWarehouse.sPhoneNumber}}</p>
                </div>
                <div class="display-flex align-items-center justify-content-flex-end">
                  <v-btn
                    :to="`/warehouse/${item.sVendorWarehouseId}`"
                    elevation="0"
                    class="button-view-detail mon-bold"
                  >
                    {{ texts.home.warehouse.textButtonView }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="empty-content display-flex align-items-center justify-content-center">
              <div>
                <!-- <div class="display-flex align-items-center justify-content-center">
                  <img
                    class="empty-image"
                    src="@/assets/images/oops.png"
                    alt=""
                  >
                </div> -->
                <p class="empty-text mon-regular">{{ texts.home.warehouse.empty.text }}</p>
                <!-- <p
                  @click="$router.push({ name: 'Warehouses' })"
                  class="empty-link mon-regular"
                >
                  {{ texts.home.warehouse.empty.link }}
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeWarehouseLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aWarehouses: [],
      restantHeight: 0,
    };
  },
  beforeMount() {
    this.getWarehouses();
  },
  updated() {
    this.resizeDivPrice();
  },
  created() {
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDivPrice);
  },
  methods: {
    resizeDivPrice: function () {
      var contentHeight = document.getElementsByClassName("text-address");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeight = max;
    },
    redirectWarehouses: function () {
      this.$router.push("/warehouses");
    },
    getWarehouses: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: 12,
          },
        }
      )
        .then((response) => {
          this.aWarehouses = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getWarehouses();
      }
    },
  },
};
</script>

<style scoped>
.text-warehouse {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.text-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.button-view-detail {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 45px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-link {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-underline:hover {
  text-decoration: underline;
}

.scroll-horizontal {
  width: 100%;
  overflow-x: auto;
  padding: 10px 0px;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  border-radius: 9px;
  width: 350px !important;
}

.content-image {
  width: 29px;
  height: 29px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  opacity: 1;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.text-title {
  text-align: left;
  font-size: 21px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
}

@media (max-width: 600px) {
  .text-title {
    font-size: 20px;
    width: 100%;
  }
  .content-titles-and-links {
    display: block;
    margin-bottom: 10px;
  }
}
</style>